<template>
    <v-container class="dashboard-page">
        <v-row>
            <v-col cols="12">
                <h1>Dashboard</h1>
                <v-card-text>
                    
                    <v-btn
                        @click="addNotification()"
                    >
                    TEST - System Alert
                    </v-btn>
                </v-card-text>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {ADD_NOTIFICATION} from "@/store/actions.type";

export default {
    name: "DashboardPage",
    methods: {
        addNotification() {
            // DEVCODE
            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                notification: {
                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                    position: "top-left",
                    type: "info",
                    body: "Testing Info",
                    state: 0,
                    length: 5000, // seconds
                    data: null,
                },
            });

            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                notification: {
                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                    position: "top-right",
                    type: "warning",
                    body: "Testing Warning",
                    state: 0,
                    length: 8000, // seconds
                    data: null,
                },
            });

            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                notification: {
                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                    position: "bottom-right",
                    type: "error",
                    body: "Testing Error",
                    state: 0,
                    length: 12000, // seconds
                    data: null,
                },
            });
        },
    }
}
</script>